import './Welcome-Page.scss';
import { Container } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { fetchAboutData } from '../../sanityQueries';

export const WelcomePageComponent = () => {
    const [resumeData, setResumeData] = useState(null);
    useEffect(() => {
        fetchAboutData().then((data) => {
            setResumeData(data[0]);
        });
    }, []);

    const resumeUrl = resumeData?.resume;

    return (
        <div
            className='text-center welcome-container'
        >
            <Container className='d-flex justify-content-center align-items-center h-100'>
                <Container className='text-white'>
                    <h1 className='mb-3 welcome-title'>Jeriko Carrera</h1>
                    <h4 className='mb-3 welcome-subtitle'>Web Developer</h4>
                    {resumeUrl ? ( // Check if resume URL exists
                        <a
                            href={resumeUrl}
                            className='resume-button'
                            download="JerikoCarreraResume.pdf" 
                            target="_blank"
                            rel="noreferrer"
                        >
                            <h5>Resume</h5>
                        </a>
                    ) : (
                        <h5>Loading Resume...</h5>
                    )}
                </Container>
            </Container>
        </div>
    )
} 
