import { Nav, Navbar, Container } from 'react-bootstrap';

import './Nav-Bar.scss';

export const NavBarComponent = () => {
    return (
        <Navbar expand="sm" className="navbar navbar-dark">
            <Container>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                    <Nav
                        style={{ maxHeight: '100px', position: 'sticky'}}
                        navbarScroll
                    >
                        <Nav.Link href="#welcome-view"><h5>Welcome</h5></Nav.Link>
                        <Nav.Link href="#intro-view"><h5>Jeriko C.</h5></Nav.Link>
                        <Nav.Link href="#projects-view"><h5>Projects</h5></Nav.Link>
                        <Nav.Link href="#contact-view"><h5>Contact</h5></Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}
